import { createStore, applyMiddleware, compose, composeWithDevTools } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
//import { combineReducers } from "redux";


const initialState = {
};

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if(serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (e) {
    // Ignore write errors;
  }
};

const peristedState = loadState();



const middleware = [thunk];

const store = createStore(
  rootReducer,
   peristedState
  );

store.subscribe(() => {
  saveState(store.getState());
});

export default store;
