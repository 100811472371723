import * as actionTypes from './actionTypes';


const initialState = {
    otp:'',
    currentTime:'',
    username:'',
};

export default function ForgotPassTeducer(state = initialState, action) {
    switch(action.type){

        case actionTypes.SET_OTP:
          var otptime = action.payload;
          state.otp = otptime.otp;
          state.currentTime = otptime.currentTime;
          state.username = otptime.username;
          return state;
          
        case actionTypes.RESET_OTP:
            state.otp = '';
            state.currentTime = '';
            state.username = '';
            return state;
        
        

        default: return state;
    
      }
}