import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import ThemeOptions from './ThemeOptions';
import * as actionTypes from './actionTypes';
import ForgotPassReducer from './ForgotPassReducer';

const initialState = {
  id:'',
  token:'',
  gmailId:'',
  facebookId:'',
  count:0,
  fcount:19,
  email:''
};



const userReducer = (state = initialState, action) =>{
  
  switch(action.type){

    case actionTypes.ADD_USER:
        var user = action.payload;
        state.id = user.id;
        state.token = user.token;
        state.gmailId = user.gmailId;
        state.facebookId = user.facebookId;
        state.email = user.email;
        return state;
   
      case actionTypes.LOGOUT_USER:
        state.id = '';
        state.token = '';
        state.gmailId = '';
        state.facebookId = '';
        state.email = '';
        return state;

      case actionTypes.UPDATE_COUNT:
        var myCount = action.payload;
        state.count = myCount.count;
        return state;
      case actionTypes.FINAL_COUNT:
        var myCount = action.payload;
        state.fcount = myCount.count;
        return state;

    default: return state;

  }


}

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  user:userReducer,
  otptime:ForgotPassReducer,
  ThemeOptions
});


