import React, {Suspense, lazy} from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap-social/bootstrap-social.css'
import './index.css';
import "./components/assets/css/material-dashboard-react.css?v=1.9.0";
import { Switch, Route, Router, Redirect} from 'react-router-dom';
import { createBrowserHistory, createHashHistory } from "history";
import store from "./store";
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
const rout = lazy(() => import('./routes'))

const hist = createBrowserHistory();
const hashHist = createHashHistory()

store.subscribe(()=>{
  console.log(store.getState(), "$$$$")
})

ReactDOM.render(
<Provider store={store}>
  <Router history={hashHist}>
    <Switch>
    <Suspense
      fallback={
        <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
          <div className="w-50 mx-auto">
            Please wait while we load CT-Onlinq for you....
          </div>
        </div>
      }>
      <Route path="/" component={rout} />      
    </Suspense>
    </Switch>
  </Router>
</Provider>
,document.getElementById('root')
);

serviceWorker.unregister();